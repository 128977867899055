<template>
  <div v-if="showFab">
    <div class="fab-content" :style="bindStyle">
      <div v-if="showArea" class="fab-button" @click="transitionArea()" :style="bindStyle">
        <v-btn color="transparent" fab :small="isIphone">
          <img src="../assets/icon-area.png" />
        </v-btn>
      </div>
      <div v-if="fabSecondary" class="fab-button" @click="fabSecondary.func" :style="bindStyle">
        <v-btn color="transparent" fab :small="isIphone">
          <v-icon v-if="fabSecondary.isMdi()" color="white" large>
            {{ fabSecondary.image() }}
          </v-icon>
          <img v-if="!fabSecondary.isMdi()" :src="fabSecondary.src()" />
        </v-btn>
      </div>

      <div v-if="fabRotation" class="fab-button fab-button-rotation"
        :class="{ '-on': this.rotationBtn, '-off': !this.rotationBtn }" @click="fabRotation.func" :style="bindStyle">
        <v-btn color="transparent" fab :small="isIphone" @click="setRotation()">
          <v-icon v-if="fabRotation.isMdi()" color="white" large>
            {{ fabRotation.image() }}
          </v-icon>
          <img v-if="!fabRotation.isMdi()" :src="fabRotation.src()" :style="bindStyle" />
        </v-btn>
      </div>


      <div class="fab-button" @click="fabPrimary.func" :style="bindStyle">
        <v-btn color="transparent" fab :small="isIphone">
          <v-icon v-if="fabPrimary.isMdi()" color="white" large>
            {{ fabPrimary.image() }}
          </v-icon>
          <img v-if="!fabPrimary.isMdi()" :src="fabPrimary.src()" />
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import constant from "../store/constant";
import models from "../store/models";
import store from "../store";
import mixinApp from "../store/mixin.app";
import mixinDetail from "../store/mixin.detail";
import DatePickerComponent from "../components/DatePickerComponent";
import SearchPeriodComponent from "../components/SearchPeriodComponent";
import RingSensorComponent from "../components/RingSensorComponent";

const vm = {
  mixins: [mixinApp, mixinDetail],
  components: {
    DatePickerComponent,
    SearchPeriodComponent,
    RingSensorComponent,
  },
  props: {
    propFabRotation: null,
    propFabPrimary: null,
    propFabSecondary: null,
    propDownloadable: false,
    propGetLoading: null,
  },
  data() {
    return {
      downloadDialog: false,
      unprocessed: true,
      processing: false,
      processed: false,
      searchDate: null,
      searchPeriod: null,
      keyDialogContent: false,
      unsubscribe: () => { },
      rotationBtn: false,
    };
  },
  computed: {
    bindStyle() {
      return {
        "--fab-operation-top": this.getDownloadButtonPosition() + "vh",
        "--fab-button-margin-top": this.getFabButtonPosition() + "vh",
        "--fab-content-bottom": this.getFabContentBottom() + "vh",
        "--fab-content-right": this.getFabContentRight() + "vw",
      };
    },
    fabRotation() {
      return this.propFabRotation;
    },
    fabPrimary() {
      return this.propFabPrimary;
    },
    fabSecondary() {
      return this.propFabSecondary;
    },
    enabled() {
      return this.user ? this.user.strategy.enabled() : false;
    },
    downloadable() {
      return this.propDownloadable;
    },
    showArea() {
      return this.propArea;
    },
    showFab() {
      return !this.propGetLoading();
    },
    allRingSensorValid() {
      return !this.isRingSensorListEmpty();
    },
    // 以後CSVダウンロードの情報のStore Getter
    /** CSV情報:DownloadUrl */
    csvInfoDownloadUrl() {
      if (this.csvInfo == null || this.csvInfo.downloadUrl == null) {
        return null;
      } else {
        return this.csvInfo.downloadUrl;
      }
    },
    /** CSV情報:SearchDate */
    csvInfoSearchDate() {
      if (this.csvInfo == null || this.csvInfo.condition.searchDate == null) {
        return "-";
      } else {
        const value = this.csvInfo.condition.searchDate;
        const valueDate = constant.toDate(value);
        const valueYmdString = valueDate.toLocaleString(
          "ja",
          constant.toLocaleStringYmdOptions()
        );
        return valueYmdString;
      }
    },
    /** CSV情報:SearchPeriod */
    csvInfoSearchPeriod() {
      if (this.csvInfo == null || this.csvInfo.condition.searchPeriod == null) {
        return "-";
      } else {
        return "直近 " + this.csvInfo.condition.searchPeriod + " 日";
      }
    },
    /** CSV情報:ExecutedDatetime */
    csvInfoExecutedDatetime() {
      if (
        this.csvInfo == null ||
        this.csvInfo.condition.executedDatetime == null
      ) {
        return "-";
      } else {
        const valueDate = constant.toDate(
          this.csvInfo.condition.executedDatetime
        );
        const valueYmdString = valueDate.toLocaleString(
          "ja",
          constant.toLocaleStringDateTimeOptions()
        );
        return valueYmdString;
      }
    },
    /** CSV情報:FileSize */
    csvInfoFileSize() {
      if (this.csvInfo == null || this.csvInfo.fileSize == null) {
        return "-";
      } else {
        const valueSize = this.csvInfo.fileSize / 1000;
        return valueSize.toFixed(1) + " KByte";
      }
    },
    /** CSV情報:DownloadExpired */
    csvInfoDownloadExpired() {
      if (this.csvInfo == null || this.csvInfo.downloadExpired == null) {
        return "-";
      } else {
        const valueDate = new Date(this.csvInfo.downloadExpired);
        //const valueDate = constant.toDate(value);
        const valueYmdString = valueDate.toLocaleString(
          "ja",
          constant.toLocaleStringDateTimeOptions()
        );
        return valueYmdString;
      }
    },
  },
  methods: {
    init() {
      this.unprocessed = true;
      //this.processing = false;
      this.processed = false;
      this.searchDate = constant.toUnixTime(constant.today());
      this.searchPeriod = constant.createSearchPeriodDefault();
      this.keyDialogContent = !this.keyDialogContent;
    },
    async downloadCsv() {
      this.unprocessed = false;
      this.processing = true;
      const param = {
        condition: this.createSearchCondition(),
        sensorCount: 0, // ToDo: あとで取得処理を入れる
      };
      await store.dispatch(constant.storeDownloadCsvAsync(), param);

      //
      this.unprocessed = true;

      this.processing = false;
      //this.processed = true;
    },
    getCsvName() {
      return new Date().toLocaleString() + ".csv";
    },
    targetDate(date) {
      this.searchDate = date;
    },
    changeSearchPeriod(searchPeriod) {
      this.searchPeriod = searchPeriod;
    },
    createSearchCondition() {
      const ret = models.createSearchCondition();

      ret.userKind = this.user.userKind;
      ret.searchUnit = constant.searchUnitCsv();
      ret.hierarchyCode = this.user.getCurrentHierarchy().hierarchyCode;
      ret.searchDate = this.searchDate;
      ret.searchPeriod = this.searchPeriod.value;
      ret.executedDatetime = constant.toUnixTime(new Date());

      return ret;
    },
    getSearchPeriod() {
      return constant.searchPeriodDefault();
    },
    saveCsv(csv) {
      // RESTから取得したCSVファイルのデータにはBOMが付加されていないので、クライアント側でUTF-8のBOMを付加します。
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blob = new Blob([bom, csv], { type: "text/csv" });
      const link = document.createElement("a");

      link.href = window.URL.createObjectURL(blob);
      link.download = this.getCsvName();
      link.click();
    },
    afterResize() {
      this.checkIphone();
    },
    getDownloadButtonPosition() {
      return this.isIphone ? 20 : 12;
    },
    getFabButtonPosition() {
      return this.isIphone ? 4 : 1;
    },
    getFabContentBottom() {
      return this.isIphone ? 13 : 8;
    },
    getFabContentRight() {
      return this.isIphone ? 2 : 1;
    },
    setRotation() {

      if (this.rotationBtn !== true) {
        this.rotationBtn = true;
      }
    }
  },
  watch: {
    downloadDialog() {
      if (this.downloadDialog) {
        this.init();
      }
    },
    rotationBtn() {

      if (this.rotationBtn) {
        setTimeout(() => {
          this.rotationBtn = false;
        }, 1000);
      }
    }
  },
  created() {
    this.afterResize();
    this.init();
    this.unsubscribe = store
      .subscribe((mutation, state) => {
        if (mutation.type === constant.storeDownloadCsv()) {
          this.saveCsv(state.download.csv);
          this.processing = false;
          this.processed = true;
        }
      })
      .bind(this);
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};

export default vm;
</script>

<style scoped>
.fab-operation {
  position: fixed;
  top: var(--fab-operation-top);
  left: 1vw;
}

.fab-content {
  position: fixed;
  bottom: var(--fab-content-bottom);
  right: var(--fab-content-right);
}

.fab-button {
  cursor: pointer;
  height: 8vh;
  width: 8vh;
  margin-top: var(--fab-button-margin-top);
}

.fab-button-large {
  cursor: pointer;
  height: 20vh;
  width: 20vh;
  margin-top: 1vh;
}

.fab-button img {
  height: 6vh;
  width: 6vh;
}

.progress {
  width: 20vw;
}

.progress span {
  display: inline-block;
  margin: 1vh;
}

.title-header {
  display: block;
  margin: 4vh 0 1vh 0;
  font-size: var(--title-font-size);
}

.title-header>span {
  position: relative;
  vertical-align: middle;
  width: 100%;
  display: inline-block;
  overflow: hidden;
}

.title-header>span::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 100%;
  height: 1px;
  flex-grow: 1;
  background-color: #666;
}

.title-header>span>img {
  width: 36px;
  height: 36px;
  vertical-align: middle;
  margin-right: 0.5em;
}

.title-header>span>label {
  vertical-align: middle;
  padding-right: 1em;
}

.sub-header {
  display: flex;
  align-items: center;
  height: 40px;
  margin-top: 1.5vh;
  margin-bottom: 1.5vh;
}

.sub-header * {
  font-size: var(--sub-header-font-size);
}

.sub-header .v-btn {
  height: 40px;
  border-radius: 0;
  background: #1e1e1e 0% 0% no-repeat padding-box;
  border: 1px solid #616161;
}

.dialog-button-close {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #00000000;
  box-shadow: none;
  padding: 0 !important;
  min-width: 0 !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
}

.dialog-button-close:hover {
  border-radius: 50%;
}
</style>
